import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { default as NextLink } from 'next/link';
import React from 'react';
import Container from './Container';
import { MenuFragmentFragment } from '../../generated/types';
import format from 'date-fns/format';

export enum FooterStyle {
    invisible,
}

export type FooterProps = FlexProps & {
    menu: MenuFragmentFragment;
};

const Footer: React.FC<FooterProps> = ({ menu, ...props }) => {
    return (
        <Box
            as="footer"
            bg="gray.100"
            color="gray.800"
            paddingY={12}
            borderTopWidth="1px"
            borderTopColor="gray.300"
            {...props}
        >
            <Container>
                <Flex justifyContent="center">
                    <Box>
                        © {format(new Date(), 'yyyy')} TVV Touristik-Verlag GmbH
                    </Box>
                    <Box marginX={8}>|</Box>
                    <Box>
                        <Box as="ul">
                            {menu && menu.items
                                ? menu.items.map(
                                      (item) =>
                                          item && (
                                              <Box
                                                  key={`${menu.name}_${item.title}`}
                                                  as="li"
                                                  marginLeft={4}
                                                  display="inline-block"
                                              >
                                                  <NextLink
                                                      href={item.url}
                                                      //   href="/[[...path]]"
                                                      passHref
                                                  >
                                                      <a>{item.title}</a>
                                                  </NextLink>
                                              </Box>
                                          )
                                  )
                                : null}
                        </Box>
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export default React.memo(Footer);
